import throttle from 'lodash/throttle';

export { navController };

const navController = () => {
    const nav = document.querySelector('nav.main');

    let scrollPosition = window.scrollY;
    const navHeight = nav.offsetHeight;

    /**
     * Zmena štýlu po skrollovaní.
     */
    window.addEventListener(
        'scroll',
        throttle(() => {
            scrollPosition = window.scrollY;

            if (scrollPosition >= navHeight) {
                nav.classList.add('white-nav');
            } else {
                nav.classList.remove('white-nav');
            }
        })
    );

    const showMobileMenuBtn = nav.querySelector('.es-show-mobile-menu');
    const mobileMenu = document.querySelector(showMobileMenuBtn.dataset.target);

    /**
     * Zobrazovanie mobilného menu.
     */
    showMobileMenuBtn.addEventListener('click', () => {
        mobileMenu.classList.add('show');
    });

    const showQuickNavBtn = nav.querySelector('.es-show-quick-nav');
    const quickNav = document.querySelector(showQuickNavBtn.dataset.target);

    /**
     * Zobrazovanie rýchlej navigácie.
     */
    showQuickNavBtn.addEventListener('click', () => {
        quickNav.classList.add('show');
    });
};
